import React, { useState, useRef, useEffect } from 'react';
import Button from '@tds/core-button';
import FlexGrid from '@tds/core-flex-grid';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Box from '@tds/core-box';
import Heading from '@tds/core-heading';
import Checkbox from '@tds/core-checkbox';
import SignatureCanvas from 'react-signature-canvas';
import Spinner from '@tds/core-spinner';
import Notification from '@tds/core-notification';
import Text from '@tds/core-text';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { history as historyPropTypes } from 'history-prop-types';

import config from '../../config';
import { feedbackTypes } from '../../config/constants';
import FormCol from '../Form/FormCol';
import TermsAndConditions from '../Terms';
import Summary from '../Summary';
import { ErrorMsg, SignatureContainer, Break } from '../../styles/elements';
import { scrollTop } from '../../utils';

const StepThree = ({ setStep, stepData, history }) => {
  const [t, i18next] = useTranslation();
  const [orderDetailsCorrect, setOrderDetailsCorrect] = useState(false);
  const { API_BASE } = config;
  const { ERROR } = feedbackTypes;
  const signatureRef = useRef();
  const [signature, setSignature] = useState(null);
  const [accept, setAccept] = useState(false);
  const [touched, setTouched] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [feedback, setFeedback] = useState(null);

  useEffect(() => {
    scrollTop();
  }, []);

  const onSignatureComplete = () => {
    const dataUrl = signatureRef.current.toDataURL();
    setSignature(dataUrl);
  };

  const completeSteps = () => {
    setTouched(true);
    if (signature && accept) {
      const completeData = { ...stepData };
      const { thatsMe } = completeData.adminData;
      delete completeData.adminData.thatsMe;
      const finalData = {
        ...completeData,
        isSelfUser: thatsMe,
        signature,
        lang: i18next.language.toUpperCase(),
      };
      setSpinning(true);
      axios
        .post(`${API_BASE}customers`, finalData)
        .then(({ data }) => {
          const { supportEmailError, customerEmailError } = data || {};
          setSpinning(false);
          if (customerEmailError) {
            setFeedback({
              type: ERROR,
              message: t('FEEDBACK_ERROR_CUSTOMER'),
            });
          } else if (supportEmailError) {
            setFeedback({
              type: ERROR,
              message: t('FEEDBACK_ERROR'),
            });
          } else {
            history.push(`/${i18next.language}/success`);
          }
        })
        .catch(() => {
          setSpinning(false);
          setFeedback({
            type: ERROR,
            message: t('FEEDBACK_ERROR'),
          });
        });
    }
  };

  return (
    <>
      <Spinner fullScreen label={t('SUBMITTING_INFO')} spinning={spinning} />
      {!orderDetailsCorrect && (
        <>
          <Summary stepData={stepData} />
          <FlexGrid>
            <FlexGrid.Row horizontalAlign="center">
              <FormCol md={6} xs={12}>
                <Button
                  variant="secondary"
                  type="submit"
                  onClick={() => setStep(0)}
                >
                  {t('I_WANT_TO_MAKE_CHANGES')}
                </Button>
              </FormCol>
              <FormCol md={6} xs={12}>
                <Button
                  onClick={() => {
                    setOrderDetailsCorrect(true);
                    scrollTop();
                  }}
                  type="submit"
                >
                  {t('ORDER_DETAILS_CORRENT')}
                </Button>
              </FormCol>
            </FlexGrid.Row>
          </FlexGrid>
        </>
      )}
      {orderDetailsCorrect && (
        <>
          <Box between={3} horizontal={3}>
            <Heading level="h2">{t('CONTRACT_TERMS')}</Heading>
            <TermsAndConditions />
            <FlexGrid gutter={false}>
              <FlexGrid.Row>
                <FlexGrid.Col md={6} xs={12}>
                  <Checkbox
                    name="agreement"
                    value="agreement"
                    checked={accept}
                    error={
                      (touched && !accept && t('MUST_ACCEPT')) || undefined
                    }
                    feedback={(touched && !accept && 'error') || undefined}
                    onChange={(e) => {
                      setAccept(e.target.checked);
                    }}
                    label={t('I_ACCEPT')}
                  />
                </FlexGrid.Col>
              </FlexGrid.Row>
            </FlexGrid>
            <div>
              <FlexGrid gutter={false}>
                <FlexGrid.Row>
                  <FlexGrid.Col md={6} xs={12}>
                    <Notification copy="en">
                      <Text bold>{t('NOTE')}:</Text> {t('CONTRACT_NOTE')}
                    </Notification>
                    <Break />
                  </FlexGrid.Col>
                </FlexGrid.Row>
                <FlexGrid.Row>
                  <FlexGrid.Col md={6} xs={12}>
                    <Heading level="h4">{t('SIGNATURE')}: </Heading>
                    {touched && !signature && (
                      <ErrorMsg>{t('MUST_ADD_SIGNATURE')}</ErrorMsg>
                    )}
                    <SignatureContainer>
                      <SignatureCanvas
                        ref={signatureRef}
                        penColor="green"
                        onEnd={onSignatureComplete}
                        backgroundColor="rgba(0,0,0,0.1)"
                        canvasProps={{
                          width: 300,
                          height: 150,
                          className: 'sigCanvas',
                        }}
                      />
                    </SignatureContainer>
                  </FlexGrid.Col>
                </FlexGrid.Row>
              </FlexGrid>
            </div>
          </Box>
          <FlexGrid>
            <FlexGrid.Row horizontalAlign="center">
              <>
                {feedback && (
                  <Notification variant={feedback.type} copy="en">
                    <Text>{feedback.message}</Text>
                  </Notification>
                )}
              </>
            </FlexGrid.Row>
            <FlexGrid.Row horizontalAlign="center">
              <FormCol md={6} xs={12}>
                <Button onClick={completeSteps} type="button">
                  {t('COMPLETE')}
                </Button>
              </FormCol>
            </FlexGrid.Row>
          </FlexGrid>
        </>
      )}
    </>
  );
};

StepThree.propTypes = {
  setStep: PropTypes.func.isRequired,
  stepData: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.shape(historyPropTypes).isRequired,
};

export default withRouter(StepThree);
