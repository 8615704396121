import React from 'react';
import Text from '@tds/core-text';
import { useTranslation } from 'react-i18next';
import { languageTypes } from '../../config/constants';

import EnTerms from './En';
import FrTerms from './Fr';

const localizedFileName = {
  [languageTypes.en]: EnTerms,
  [languageTypes.fr]: FrTerms,
};

const Terms = () => {
  const [t, i18next] = useTranslation();
  const TermsAndConditions = localizedFileName[i18next.language];
  return (
    <>
      <TermsAndConditions />
      <Text>
        {t('TO_REVIEW_TERMS_VISIT')}{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://akirahealth.ca/sbs"
        >
          akirahealth.ca/sbs
        </a>
      </Text>
    </>
  );
};

export default Terms;
