import React from 'react';

import { TermsAndConditions } from '../../styles/elements';

const Terms = () => {
  return (
    <TermsAndConditions>
      <p>&nbsp;</p>
      <p>
        <span>
          The following terms, including the General Terms and Conditions set
          out at the Akira Health Website:{' '}
        </span>{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://akirahealth.ca/sbs"
        >
          akirahealth.ca/sbs
        </a>{' '}
        <span>
          govern the use of the virtual care services described herein (the
          &ldquo;
        </span>
        <strong style={{ fontWeight: 600 }}>Akira Solution</strong>
        <span>&rdquo;) you (the &ldquo;</span>
        <strong style={{ fontWeight: 600 }}>Client&rdquo;</strong>
        <span>) have ordered from Right Health Inc. (&ldquo;</span>
        <strong style={{ fontWeight: 600 }}>Akira Health</strong>
        <span>
          &rdquo;) for use by your Authorized End Users.&nbsp; A copy of these
          Business Terms of Use and the General Terms and Conditions
          (collectively the &ldquo;
        </span>
        <strong style={{ fontWeight: 600 }}>Agreement</strong>
        <span>
          &rdquo;) may be downloaded, saved and printed for your
          reference.&nbsp;&nbsp;
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          By clicking &ldquo;I AGREE&rdquo; or similar electronic acceptance,
          you agree with these Business Terms of Use and the General Terms and
          Conditions. If you do not accept and agree to be bound by these
          Business Terms of Use and the General Terms and Conditions, please do
          not use the Akira Solution.
        </span>
      </p>
      <p>
        <span>&nbsp;</span>
      </p>
      <p>
        <span>
          Capitalised terms used below shall have the meaning given to them in
          the General Terms and Conditions.
        </span>
        <strong style={{ fontWeight: 600 }}>
          {' '}
          Please carefully read the General Terms and Conditions as they form
          part of this agreement and contain important legal rights,
          restrictions and obligations which must be accepted by you prior to
          activating the account.&nbsp; During the initial twelve (12) month
          period, the Akira Solution shall be provided at no cost to the Client,
          following which standard rates will automatically apply (as set out in
          the General Terms and Conditions), unless the Client advises Akira
          Health that it will not be renewing the Agreement.
        </strong>{' '}
        <strong style={{ fontWeight: 600 }}>
          These General Terms and Conditions include, but are not limited to,
          the following:&nbsp;
        </strong>
      </p>
      <p>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      </p>
      <p>
        <span>
          From time to time Akira Health may amend General Terms and Conditions,
          and if amended, Akira Health will post the amended version on the
          Akira Health Website (see link above) and provide you with notice of
          the change by invoice notification, email or otherwise. Unless Akira
          Health indicates otherwise, the effective date of the amended General
          Terms and Conditions will be the date of the notification. If you do
          not agree with amended General Terms and Conditions you must notify
          Akira Health in writing within ten (10) days of receipt of the
          notification, failing which you will be deemed to have accepted the
          amended General Terms and Conditions.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong style={{ fontWeight: 600 }}>
          Services to Authorized End Users:{' '}
        </strong>
        <span>
          The Client acknowledges that (a) the Services to the Authorized End
          Users are provided by Akira Health and its providers (including Health
          Professionals and Allied Health Practitioners) via the Akira App and
          such use is governed by the End User Terms; and (b) use of the Akira
          App by Authorized End Users and the supply of any Services through the
          Akira App requires Eligible Users to enter into End User Terms with
          Akira Health.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          The Client acknowledges and agrees that the Akira Solution may be
          modified from time to time due to changes in the methods of quality
          health service, current knowledge of best medical practices,
          provincial health guidelines, applicable law or safety requirements,
          or otherwise in such manner in which Akira Health or its&rsquo;
          affiliates considers (in its sole discretion) are reasonable or
          desirable.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong style={{ fontWeight: 600 }}>Client Obligations:&nbsp; </strong>
        <span>
          Within 10 days of accepting these terms and the General Terms and
          Conditions, Client shall provide to Akira Health a complete list of
          all Eligible Individuals (the &ldquo;
        </span>
        <strong style={{ fontWeight: 600 }}>Eligibility List</strong>
        <span>
          &rdquo;). Client is further obligated to maintain the accuracy of the
          Eligibility List and to advise Akira Health of any and all changes to
          this Eligibility List in a timely manner, but no later than 30 days
          following any addition or deletion of employees to the Eligibility
          List. Eligibility List information shall include, at minimum, the
          Eligible Individual&rsquo;s name, French or English language
          preference, province or territory of residence, email address, and
          unique employee identifier (e.g. employee number).&nbsp; During the
          Free Period, the maximum number of Eligible Individuals is limited to
          eleven (11), which includes one (1) account set aside for the business
          owner.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong style={{ fontWeight: 600 }}>
          Privacy and Use of Eligible User Information
        </strong>
        <span>
          {' '}
          Each Eligible User accessing the Services will be required to accept
          Akira Health&rsquo;s privacy statement and End User Terms then in
          effect, as amended from time to time in accordance with such privacy
          statement and End User Terms (collectively, the &ldquo;
        </span>
        <strong style={{ fontWeight: 600 }}>Policies</strong>
        <span>
          &rdquo;).&nbsp; Unless otherwise set out in the Policies, Akira Health
          will access, collect, use and disclose the Personal Information of
          Eligible Users only as reasonably necessary to provide the Services,
          to obtain payment and/or to provide Eligible Users with information,
          newsletters, invitations and other communications from Akira Health in
          relation to the Services, and as required or permitted by applicable
          law.&nbsp; Akira Health will comply with applicable Privacy Laws
          relating to Personal Information.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong style={{ fontWeight: 600 }}>
          Intellectual Property Rights:&nbsp;{' '}
        </strong>
        <span>
          As between Client on the one hand and Akira Health and its providers
          (including Health Professionals) on the other hand,&nbsp; Akira Health
          and/or its partners shall own and retain all intellectual property
          rights, whether currently existing or hereafter developed or acquired,
          (and all applications, disclosures and registrations with respect
          thereto) in connection with and to the Services, the Akira App, any
          documentation, and all legally protectable elements, derivative works,
          modifications and enhancements thereto, whether or not developed in
          conjunction with Client, and whether or not developed by Akira Health
          and its providers, Client or any contractor, subcontractor or agent
          for Akira Health and its partners or Client. Nothing in the Agreement
          shall cause the transfer of ownership of, or grant a licence, under
          any intellectual property rights of Akira Health or its affiliates, to
          the Client.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong style={{ fontWeight: 600 }}>Service Period and Fees: </strong>
        <span>
          The Start Date begins on the date the Client has accepted the
          Agreement. The End Date of the Free Period is the first anniversary of
          the Start Date. During the initial twelve (12) month period, the Akira
          Solution shall be provided at no cost to the Client (the &ldquo;
        </span>
        <strong style={{ fontWeight: 600 }}>Free Period</strong>
        <span>
          &rdquo;).&nbsp; Following the End Date, the Agreement will be renewed
          automatically on a month to month basis for the monthly fees listed in
          the Fees and Payment Terms section of the General Terms and Conditions
          until: (i) the Client advises Akira Health that it will be renewing
          the Agreement for a period of one year, in which case the annual fee
          listed in the Fees and Payment Terms section of the General Terms and
          Conditions will apply; (ii) either Akira Health or the Client
          terminate or change the Agreement; (iii) Akira Health adjusts its Fees
          by giving you notice at least one month in advance, or (iv) the
          parties enter into a new agreement that supersedes the Agreement.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong style={{ fontWeight: 600 }}>
          Warranty and Disclaimer:&nbsp;{' '}
        </strong>
        <span>
          Except as expressly set forth herein and to the extent permitted by
          law, the Services, the Akira App, and any data (including third party
          data), information or services accessed or provided in connection with
          the Agreement are provided &ldquo;as is&rdquo; and &ldquo;as
          available&rdquo;. Akira Health and its affiliates, disclaim all
          statutory representations, conditions and warranties, express or
          implied, including but not limited to any implied warranties of
          merchantability, accuracy, integrity, freedom, non-infringement or
          fitness for a particular purpose, or arising from the course of
          performance or course of dealing, or that Services or the Akira App
          will provide error-free or uninterrupted functionalities.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong style={{ fontWeight: 600 }}>
          Limitation of Liability:&nbsp;{' '}
        </strong>
        <span>
          In no event will Akira Health, its affiliates, and their respective
          directors, employees, contractors or agents be liable to the Client or
          to any other entity arising out of or related to the Agreement (a) for
          any special, indirect, incidental or consequential damages, (b) for
          any loss of data or profit or business interruption, arising out of or
          related to the Agreement, under any theory, whether or not
          foreseeable, whether or not advised of the possibility of such damage,
          (c) in an aggregate amount exceeding $100.00 or the Akira Solution
          Fees paid by Client to Akira Health in the six (6) months before the
          event giving rise to the claim, whichever is greater. This section
          shall survive the termination of the Agreement.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong style={{ fontWeight: 600 }}>Arbitration:&nbsp; </strong>
        <span>
          Akira Health and the Client shall use arbitration to resolve disputes
          between them arising from or relating to the Agreement, whether the
          dispute is based in contract, tort (including negligence), statute, or
          otherwise. If the business representatives of the parties have not
          been able to resolve any such dispute, in order to proceed with the
          dispute a party must submit the dispute to private and confidential
          arbitration before a single arbitrator.&nbsp; Arbitration will take
          place in the capital of the province where the Client is located under
          the rules of the ADR Institute of Canada, including its rules as to
          initiation and submission of a dispute, appointment of an arbitrator,
          and responsibility for the fees and expenses arising from or relating
          to arbitration. This Section does not apply to the collection of any
          amounts owing to Akira Health or any injunction application, except
          that, in the case of an injunction application, the parties shall use
          arbitration to resolve the dispute that led to the application. Venue
          and jurisdiction of any court proceedings shall be in the province of
          Ontario.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          If there is any conflict or inconsistency between the Business Terms
          of Use and the General Terms Conditions on-line, the General Terms and
          Conditions on-line will take precedence.
        </span>
      </p>
    </TermsAndConditions>
  );
};

export default Terms;
