import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@tds/core-tooltip';
import { useTranslation } from 'react-i18next';

import Field from '../Form/Field';

const CommonFields = ({ touched, errors }) => {
  const [t] = useTranslation();
  return (
    <>
      <Field
        name="firstName"
        label={t('FIRST_NAME')}
        touched={touched}
        errors={errors}
      />
      <Field
        name="lastName"
        label={t('LAST_NAME')}
        touched={touched}
        errors={errors}
      />
      <Field
        name="email"
        type="email"
        label={t('EMAIL')}
        touched={touched}
        errors={errors}
      />
      <Field
        name="mobileNumber"
        type="mask"
        label={t('MOBILE_NUMBER')}
        touched={touched}
        errors={errors}
        tooltip={<Tooltip copy="en">{t('MOBILE_TOOLTIP')}</Tooltip>}
      />
    </>
  );
};

CommonFields.propTypes = {
  touched: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default CommonFields;
