import React from 'react';
import Heading from '@tds/core-heading';
import Text from '@tds/core-text';
import Box from '@tds/core-box';
import Button from '@tds/core-button';
import { useTranslation } from 'react-i18next';

import Container from '../../components/Container';
import { Link } from '../../styles/elements';

const LandingPage = () => {
  const [t, i18next] = useTranslation();

  return (
    <Container>
      <Box between={3} inset={3}>
        <Heading level="h2">{t('LANDING_PAGE_TITLE')}</Heading>
        <Text>{t('LANDING_PAGE_MSG')}</Text>
        <Box>
          <Link to={`/${i18next.language}/registration-form`}>
            <Button>{t('LANDING_PAGE_BTN_TXT')}</Button>
          </Link>
        </Box>
      </Box>
    </Container>
  );
};

export default LandingPage;
