import React from 'react';

import { TermsAndConditions } from '../../styles/elements';

const Terms = () => {
  return (
    <TermsAndConditions>
      <p>&nbsp;</p>
      <p>
        <span>
          Les conditions et modalit&eacute;s suivantes, y compris les Conditions
          G&eacute;n&eacute;rales &eacute;nonc&eacute;es sur le site Web
          d&apos;Akira Sant&eacute;:{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://akirahealth.ca/sbs"
          >
            akirahealth.ca/sbs
          </a>{' '}
          r&eacute;gissent l&apos;utilisation des services de soins virtuels
          d&eacute;crits aux pr&eacute;sentes (la &laquo;
        </span>
        <strong style={{ fontWeight: 600 }}>Solution Akira</strong>
        <span>&raquo;) que vous (le &laquo;</span>
        <strong style={{ fontWeight: 600 }}>Client</strong>
        <span>
          &raquo;) ) avez command&eacute; aupr&egrave;s de Right Health Inc.
          (&laquo;
        </span>
        <strong style={{ fontWeight: 600 }}>Akira Sant&eacute;</strong>
        <span>
          &raquo;) pour utilisation par vos Utilisateurs finaux
          autoris&eacute;s. Une copie des pr&eacute;sentes Conditions et
          modalit&eacute;s d&rsquo;affaire et des Conditions
          G&eacute;n&eacute;rales (collectivement le &laquo;{' '}
        </span>
        <strong style={{ fontWeight: 600 }}>Contrat </strong>
        <span>
          &raquo;) peut &ecirc;tre t&eacute;l&eacute;charg&eacute;e,
          sauvegard&eacute;e et imprim&eacute;e pour votre
          r&eacute;f&eacute;rence.
        </span>
      </p>{' '}
      <br />
      <p>
        <span>
          En cliquant sur &laquo;J&apos;ACCEPTE&raquo; ou une acceptation
          &eacute;lectronique similaire, vous acceptez les pr&eacute;sentes
          Conditions et modalit&eacute;s d&rsquo;affaire et les Conditions
          G&eacute;n&eacute;rales. Si vous n&apos;acceptez pas d&apos;&ecirc;tre
          li&eacute; par les pr&eacute;sentes Conditions et modalit&eacute;s
          d&rsquo;affaire et les Conditions G&eacute;n&eacute;rales, veuillez ne
          pas utiliser la Solution Akira.
        </span>
      </p>{' '}
      <br />
      <p>
        <span>
          Les termes en majuscules utilis&eacute;s ci-dessous ont la
          signification qui leur est donn&eacute;e dans les Conditions
          G&eacute;n&eacute;rales.{' '}
        </span>
        <strong style={{ fontWeight: 600 }}>
          Veuillez lire attentivement les Conditions G&eacute;n&eacute;rales car
          elles font partie de ce Contrat et contiennent des droits,
          restrictions et obligations juridiques importants que vous devez
          accepter avant d&apos;activer le compte. Au cours de la p&eacute;riode
          initiale de douze (12) mois, la Solution Akira sera fournie sans frais
          pour le Client, apr&egrave;s quoi les tarifs standards
          s&apos;appliqueront automatiquement (tel que stipul&eacute; dans les
          Conditions G&eacute;n&eacute;rales), sauf si le Client avise Akira
          Sant&eacute; qu&apos;il ne renouvellera pas ce Contrat. Les
          pr&eacute;sentes Conditions G&eacute;n&eacute;rales comprennent, sans
          s&apos;y limiter, les &eacute;l&eacute;ments suivants{' '}
        </strong>
        <span>:</span>
      </p>{' '}
      <br />
      <p>
        <span>
          De temps &agrave; autre, Akira Sant&eacute; peut modifier les
          Conditions G&eacute;n&eacute;rales, et si elles sont modifi&eacute;es,
          Akira Sant&eacute; publiera la version modifi&eacute;e sur le site Web
          d&apos;Akira Sant&eacute; (voir lien ci-dessus) et vous informera du
          changement par notification &agrave; m&ecirc;me votre facture, par
          e-mail ou autre. Sauf indication contraire d&apos;Akira Sant&eacute;,
          la date d&apos;entr&eacute;e en vigueur des Conditions
          G&eacute;n&eacute;rales modifi&eacute;es sera la date de la
          notification. Si vous n&apos;acceptez pas les Conditions
          G&eacute;n&eacute;rales modifi&eacute;es, vous devez en informer Akira
          Sant&eacute; par &eacute;crit dans un d&eacute;lai d&rsquo;un plus dix
          (10) jours suivant la r&eacute;ception de la notification, faute de
          quoi vous serez r&eacute;put&eacute; avoir accept&eacute; les
          Conditions G&eacute;n&eacute;rales modifi&eacute;es.
        </span>
      </p>{' '}
      <br />
      <p>
        <strong style={{ fontWeight: 600 }}>
          Services aux Utilisateurs finaux autoris&eacute;s
        </strong>
        <span>
          .&nbsp; Le Client reconna&icirc;t que a)&nbsp;les Services aux
          Utilisateurs finaux autoris&eacute;s sont offerts par Akira
          Sant&eacute; et ses fournisseurs (y compris les Professionnels de la
          sant&eacute; et les Professionnels des soins de sant&eacute;
          compl&eacute;mentaires) par l&rsquo;interm&eacute;diaire de
          l&rsquo;Application Akira et que cette utilisation est r&eacute;gie
          par les Modalit&eacute;s relatives aux utilisateurs finaux; et
          b)&nbsp;l&rsquo;utilisation de l&rsquo;Application Akira par les
          Utilisateurs finaux autoris&eacute;s et la prestation de tout Service
          par l&rsquo;interm&eacute;diaire de l&rsquo;Application Akira
          n&eacute;cessitent que les Utilisateurs admissibles concluent les
          Modalit&eacute;s relatives aux utilisateurs finaux avec Akira
          Sant&eacute;.
        </span>
      </p>{' '}
      <br />
      <p>
        <span>
          Le Client reconna&icirc;t et convient que la Solution Akira peut
          &ecirc;tre modifi&eacute;e selon les besoins en raison de
          modifications des modes de prestation de services de sant&eacute; de
          qualit&eacute;, de l&rsquo;&eacute;tat actuel des connaissances en
          mati&egrave;re de pratiques m&eacute;dicales exemplaires, des lignes
          directrices provinciales en mati&egrave;re de sant&eacute;, de la
          l&eacute;gislation applicable ou des exigences en mati&egrave;re de
          s&eacute;curit&eacute;, ou autrement selon ce qu&rsquo;Akira
          Sant&eacute; ou ses soci&eacute;t&eacute;s affili&eacute;es estiment
          (&agrave; leur discr&eacute;tion exclusive) raisonnable ou
          souhaitable.
        </span>
      </p>{' '}
      <br />
      <p>
        <strong style={{ fontWeight: 600 }}>Obligations du Client</strong>
        <span>
          {' '}
          : Dans les dix (10)&nbsp;jours suivant l&rsquo;acceptation des
          pr&eacute;sentes Conditions et modalit&eacute;s d&rsquo;affaire et des
          Conditions G&eacute;n&eacute;rales, le Client fournira &agrave; Akira
          Sant&eacute; une liste compl&egrave;te de toutes les Personnes
          admissibles (la &laquo;&nbsp;
        </span>
        <strong style={{ fontWeight: 600 }}>
          Liste d&rsquo;admissibilit&eacute;
        </strong>
        <span>
          &nbsp;&raquo;). Le Client a &eacute;galement l&rsquo;obligation de
          maintenir l&rsquo;exactitude de la Liste d&rsquo;admissibilit&eacute;
          et d&rsquo;aviser Akira Sant&eacute; de toute modification
          apport&eacute;e &agrave; cette Liste d&rsquo;admissibilit&eacute; en
          temps opportun, mais au plus tard 30&nbsp;jours suivant tout ajout ou
          tout retrait d&rsquo;employ&eacute;s &agrave; la Liste
          d&rsquo;admissibilit&eacute;. Les renseignements qui figurent &agrave;
          la Liste d&rsquo;admissibilit&eacute; doivent comprendre au minimum le
          nom de la Personne admissible, sa pr&eacute;f&eacute;rence
          linguistique entre l&rsquo;anglais et le fran&ccedil;ais, sa province
          ou son territoire de r&eacute;sidence, son adresse de courriel et son
          identifiant unique d&rsquo;employ&eacute; (p.&nbsp;ex. le
          num&eacute;ro d&rsquo;employ&eacute;).&nbsp; Pendant la P&eacute;riode
          gratuite, le nombre maximum de Personnes admissibles est limit&eacute;
          &agrave; onze (11), ce qui comprend un (1) compte
          r&eacute;serv&eacute; au propri&eacute;taire de l&apos;entreprise.
        </span>
      </p>{' '}
      <br />
      <p>
        <strong style={{ fontWeight: 600 }}>
          Vie priv&eacute;e et usage des renseignements d&rsquo;Utilisateur
          admissible
        </strong>
        <span>
          .&nbsp; Chaque Utilisateur admissible qui acc&eacute;dera aux Services
          devra accepter l&rsquo;&eacute;nonc&eacute; de confidentialit&eacute;
          et les Conditions d&rsquo;utilisation relatives aux Utilisateurs
          finaux d&rsquo;Akira Sant&eacute; alors en vigueur, ainsi que leur
          version modifi&eacute;e de temps &agrave; autre conform&eacute;ment
          &agrave; cet &eacute;nonc&eacute; de confidentialit&eacute; et
          &agrave; ces Modalit&eacute;s relatives aux utilisateurs finaux
          (collectivement, les &laquo;&nbsp;
        </span>
        <strong style={{ fontWeight: 600 }}>politiques</strong>
        <span>
          &nbsp;&raquo;). Sauf disposition contraire dans les Politiques, Akira
          Sant&eacute; acc&eacute;dera aux Renseignements Personnels des
          Utilisateurs admissibles, les recueillera, les utilisera et les
          divulguera uniquement selon ce qui est raisonnablement
          n&eacute;cessaire pour fournir les Services, pour obtenir les
          paiements ou pour fournir aux Utilisateurs admissibles des
          renseignements, des bulletins d&rsquo;information, des invitations et
          d&rsquo;autres communications de la part d&rsquo;Akira Sant&eacute; en
          lien avec les Services, et selon ce qui est exig&eacute; ou permis par
          les Lois en mati&egrave;re de protection des renseignements
          personnels. Akira Sant&eacute; se conformera aux Lois en
          mati&egrave;re de protection des renseignements personnels applicables
          en ce qui concerne les Renseignements Personnels.
        </span>
      </p>{' '}
      <br />
      <p>
        <strong style={{ fontWeight: 600 }}>
          Droits li&eacute;s &agrave; la propri&eacute;t&eacute; intellectuelle
        </strong>
        <span>
          .&nbsp; Pour valoir entre le Client, d&rsquo;une part, et Akira
          Sant&eacute; ainsi que ses fournisseurs (y compris les Professionnels
          de la sant&eacute;), d&rsquo;autre part, Akira Sant&eacute; et ses
          partenaires d&eacute;tiendront et conserveront tous les droits
          li&eacute;s &agrave; la propri&eacute;t&eacute; intellectuelle qui
          existe actuellement ou qui sera cr&eacute;&eacute;e ou acquise
          ult&eacute;rieurement (et l&rsquo;ensemble des demandes, des
          divulgations et des enregistrements concernant cette
          propri&eacute;t&eacute; intellectuelle) en lien avec les Services,
          l&rsquo;Application Akira, toute la documentation, tous les
          &eacute;l&eacute;ments pouvant l&eacute;galement &ecirc;tre
          prot&eacute;g&eacute;s, toutes les &oelig;uvres d&eacute;riv&eacute;es
          et toutes les modifications et les am&eacute;liorations qui y sont
          apport&eacute;es, ou s&rsquo;y rapportant, qu&rsquo;elle soit ou non
          cr&eacute;&eacute;e en collaboration avec le Client, et qu&rsquo;elle
          soit ou non cr&eacute;&eacute;e par Akira Sant&eacute; et ses
          fournisseurs, le Client ou tout entrepreneur, sous-traitant ou
          mandataire d&rsquo;Akira Sant&eacute; ou du Client. Rien dans le
          pr&eacute;sent Contrat ne saurait entra&icirc;ner le transfert de la
          propri&eacute;t&eacute; ou la concession d&rsquo;une licence au Client
          en ce qui concerne les droits de propri&eacute;t&eacute;
          intellectuelle d&rsquo;Akira Sant&eacute; ou de ses
          soci&eacute;t&eacute;s affili&eacute;es.
        </span>
      </p>{' '}
      <br />
      <p>
        <strong style={{ fontWeight: 600 }}>
          P&eacute;riode de Service et Tarifs
        </strong>
        <span>
          {' '}
          : La Date de d&eacute;but commence &agrave; la date &agrave; laquelle
          le Client a accept&eacute; le Contrat. La Date de fin de la
          P&eacute;riode gratuite est le premier anniversaire de la Date de
          d&eacute;but.&nbsp; Au cours de la p&eacute;riode initiale de douze
          (12) mois, la Solution Akira sera fournie sans frais pour le client
          (la &laquo;{' '}
        </span>
        <strong style={{ fontWeight: 600 }}>P&eacute;riode gratuite </strong>
        <span>
          &raquo;). Apr&egrave;s la Date de fin, le Contrat sera
          renouvel&eacute; automatiquement sur une base mensuelle pour les frais
          mensuels indiqu&eacute;s dans la section Tarifs et modalit&eacute;s de
          paiement des Conditions G&eacute;n&eacute;rales, et ce,
          jusqu&apos;&agrave; ce que: (i) le Client informe Akira Sant&eacute;
          qu&apos;il renouvellera le Contrat pour une p&eacute;riode d&apos;un
          an, auquel cas les Tarifs annuels indiqu&eacute;s dans la section
          Tarifs et modalit&eacute;s de paiement des Conditions
          G&eacute;n&eacute;rales s&apos;appliqueront; (ii) soit Akira
          Sant&eacute; ou le Client r&eacute;silient ou modifient le Contrat;
          (iii) Akira Sant&eacute; ajuste ses tarifs en vous pr&eacute;venant au
          moins un mois &agrave; l&apos;avance, ou (iv) les parties concluent un
          nouvel accord qui remplace le Contrat.
        </span>
      </p>{' '}
      <br />
      <p>
        <strong style={{ fontWeight: 600 }}>
          Garantie et exon&eacute;ration de responsabilit&eacute;
        </strong>
        <span>
          .&nbsp; Sauf disposition contraire aux pr&eacute;sentes, et dans la
          mesure permise par la loi, les Services, l&rsquo;Application Akira et
          toute donn&eacute;e (y compris les donn&eacute;es de tiers), toute
          information ou tout service fournis en lien avec le pr&eacute;sent
          Contrat ou tout acc&egrave;s &agrave; ces &eacute;l&eacute;ments sont
          fournis &laquo;&nbsp;en l&rsquo;&eacute;tat&nbsp;&raquo; et
          &laquo;&nbsp;selon la disponibilit&eacute;&nbsp;&raquo;. Akira
          Sant&eacute; et ses soci&eacute;t&eacute;s affili&eacute;es nient
          l&rsquo;ensemble des d&eacute;clarations, des conditions et des
          garanties statutaires, expresses ou implicites, y compris, mais sans
          s&rsquo;y limiter, les garanties implicites de qualit&eacute;
          marchande, d&rsquo;exactitude, d&rsquo;int&eacute;grit&eacute;, de
          libert&eacute;, d&rsquo;absence de contrefa&ccedil;on ou
          d&rsquo;adaptation &agrave; un usage particulier, ou d&eacute;coulant
          des modalit&eacute;s d&rsquo;ex&eacute;cution ou des rapports
          d&rsquo;affaires, ou selon lesquelles les services ou
          l&rsquo;Application Akira offriront des fonctionnalit&eacute;s
          exemptes d&rsquo;erreurs ou d&rsquo;interruptions.
        </span>
      </p>{' '}
      <br />
      <p>
        <strong style={{ fontWeight: 600 }}>
          Limitation de responsabilit&eacute;
        </strong>
        <span>
          &nbsp;: En aucune circonstance Akira Sant&eacute;, ses
          soci&eacute;t&eacute;s affili&eacute;es et leurs administrateurs,
          employ&eacute;s, entrepreneurs ou mandataires respectifs
          n&rsquo;assumeront quelque responsabilit&eacute; que ce soit envers le
          Client ou toute autre personne d&eacute;coulant du pr&eacute;sent
          contrat ou s&rsquo;y rapportant en ce qui concerne a)&nbsp;tout
          dommage particulier, indirect, accessoire ou immat&eacute;riel,
          b)&nbsp;toute perte de donn&eacute;es ou de profits ou toute
          interruption des activit&eacute;s d&eacute;coulant du pr&eacute;sent
          Contrat ou s&rsquo;y rapportant, en vertu de toute th&eacute;orie,
          pr&eacute;visibles ou non, peu importe qu&rsquo;ils aient
          &eacute;t&eacute; inform&eacute;s ou non de la possibilit&eacute;
          d&rsquo;un tel dommage, c)&nbsp;une somme globale sup&eacute;rieure
          &agrave; $100.00 ou aux Tarifs de la Solution Akira pay&eacute;s
          &agrave; Akira Sant&eacute; par le Client dans au cours des
          (6)&nbsp;mois pr&eacute;c&eacute;dant l&rsquo;&eacute;v&eacute;nement
          ayant donn&eacute; lieu &agrave; la r&eacute;clamation, selon le plus
          &eacute;lev&eacute; des deux montants. La pr&eacute;sente clause
          survivra &agrave; la r&eacute;siliation du pr&eacute;sent Contrat.
        </span>
      </p>{' '}
      <br />
      <p>
        <strong style={{ fontWeight: 600 }}>Arbitrage.</strong>
        <span>
          &nbsp; Akira Sant&eacute; et le Client auront recours &agrave;
          l&rsquo;arbitrage afin de r&eacute;gler les diff&eacute;rends entre
          eux d&eacute;coulant du pr&eacute;sent Contrat ou s&rsquo;y
          rapportant, que le diff&eacute;rend soit fond&eacute; sur un contrat,
          un acte d&eacute;lictuel (y compris la n&eacute;gligence), une loi ou
          un autre fondement. Si les repr&eacute;sentants d&rsquo;entreprise des
          parties n&rsquo;ont pas r&eacute;ussi &agrave; r&eacute;gler un tel
          diff&eacute;rend, une partie devra soumettre le diff&eacute;rend
          &agrave; un arbitrage priv&eacute; et confidentiel devant un arbitre
          unique afin qu&rsquo;il soit tranch&eacute;. L&rsquo;arbitrage aura
          lieu dans la capitale de la province o&ugrave; le Client est
          situ&eacute;, conform&eacute;ment aux r&egrave;gles de
          l&rsquo;Institut d&rsquo;arbitrage et de m&eacute;diation du Canada, y
          compris ses r&egrave;gles relatives &agrave; l&rsquo;introduction et
          &agrave; la pr&eacute;sentation d&rsquo;un diff&eacute;rend, &agrave;
          la nomination d&rsquo;un arbitre et &agrave; la responsabilit&eacute;
          des honoraires et des d&eacute;bours d&eacute;coulant de
          l&rsquo;arbitrage ou s&rsquo;y rapportant. La pr&eacute;sente clause
          ne s&rsquo;applique pas au recouvrement de toute somme due &agrave;
          Akira Sant&eacute; ou &agrave; toute demande d&rsquo;injonction ;
          toutefois, dans le cas d&rsquo;une demande d&rsquo;injonction, les
          parties devront avoir recours &agrave; l&rsquo;arbitrage afin de
          r&eacute;gler le diff&eacute;rend qui a donn&eacute; lieu &agrave; la
          demande. Les tribunaux ayant comp&eacute;tence d&rsquo;attribution et
          comp&eacute;tence territoriale en ce qui concerne toute
          proc&eacute;dure judiciaire seront ceux de la province de
          l&rsquo;Ontario.
        </span>
      </p>{' '}
      <br />
      <p>
        <span>
          En cas de conflit ou d&apos;incoh&eacute;rence entre les Conditions et
          modalit&eacute;s d&rsquo;affaire et les Conditions
          G&eacute;n&eacute;rales en ligne, les Conditions
          G&eacute;n&eacute;rales en ligne pr&eacute;vaudront.
        </span>
      </p>{' '}
      <br />
      <p>&nbsp;</p> <br />
    </TermsAndConditions>
  );
};

export default Terms;
